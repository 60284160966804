import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/manifest'

class VoyageManifestService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    show(id, operatedByOfficeId = 0) {
        return http.get(`${URL}/${id}/get/0/${operatedByOfficeId}`)
    }
    updateOpenClosed(id, data) {
        return http.post(`${this.getUrl()}/${id}/update-status`, data)
    }
    sendStatusRequest(id, data) {
        return http.put(`/api/bill-of-lading/${id}/update-approval`, data)
    }
}

export default new VoyageManifestService()